<template>
  <article>
    <!-- Início Principal -->
    <v-toolbar dense flat>
      <v-app-bar-nav-icon>
        <v-icon>fas fa-file-upload</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Importar Volumes Extras</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card outlined class="pa-2">
      <v-row dense class="ma-2 mb-1">
        <v-col cols="12" md="5">
          <v-file-input hide-details="auto" background-color="#E3E1D9" outlined dense color="#018656"
          label="Selecione o arquivo" persistent-hint v-model="uploadFiles" hint="Informe o arquivo CSV."
          :loading="carregando" loader-height="4"></v-file-input>
        </v-col>
      </v-row>

      <v-row dense class="justify-end ma-1 mb-0">
        <v-card-subtitle class="pb-2">
          <a href="/csv_produtos/volume_extra.csv" download style="text-decoration: none;">
            <v-icon color="#1976d2" small class="mr-2">fas fa-file-upload</v-icon>
            Modelo de arquivo CSV
          </a>
        </v-card-subtitle>

        <v-spacer></v-spacer>

        <v-btn @click="importar()" color="#018454" style="color: white;">
          <v-icon color="white" class="mr-2" small>fas fa-file-upload</v-icon>
          Importar
        </v-btn>
      </v-row>
    </v-card>
    <transition name="fade">
      <v-card v-if="dataProd.length" outlined class="mt-2 pa-2">
        <!-- <v-card-title v-if="!dataProd.length" class="d-flex justify-center pb-0" style="flex-direction: column;">
          CAMPOS PARA IMPORTAÇÃO DE VOLUMES EXTRAS

          <v-card-subtitle class="pt-2">SEGUIR AS COLUNAS DO MODELO!!!</v-card-subtitle>
        </v-card-title>

        <v-card-text v-if="!dataProd.length">
          <v-row style="height: calc(100vh - 394px); overflow-y: auto;">
            <pre>
            - CODIGO: Código do Produto
            - TIPO: Alfanumérico
            - OBRIGATÓRIO: SIM
            ---
            - COMPRIMENTO: Dimensão, em METROS, do comprimento do volume extra
            - TIPO: Numérico decimal, com até 02 (duas) casas, separados por PONTO
            - EXEMPLO: 1.23
            - OBRIGATÓRIO: NÃO
            ---
            - LARGURA: Dimensão, em METROS, da largura do volume extra
            - TIPO: Numérico decimal, com até 02 (duas) casas, separados por PONTO
            - EXEMPLO: 1.23
            - OBRIGATÓRIO: NÃO
            ---
            - ALTURA: Dimensão, em METROS, da altura do volume extra
            - TIPO: Numérico decimal, com até 02 (duas) casas, separados por PONTO
            - EXEMPLO: 1.23
            - OBRIGATÓRIO: NÃO
            ---
            - CUBAGEM: Cálculo, em METROS, da cubagem do volume extra
            - TIPO: Numérico decimal, com até 06 (seis) casas, separados por PONTO
            - EXEMPLO: 1.860867
            - OBRIGATÓRIO: NÃO
            ---
            - PESO: Massa calculada, em QUILOS, do volume extra
            - TIPO: Numérico decimal, com até 03 (três) casas, separados por PONTO
            - EXEMPLO: 1.234
            - OBRIGATÓRIO: NÃO
            ---
            - VOLUME: Quantidade de itens neste volume extra
            - TIPO: Numérico inteiro
            - EXEMPLO: 1
            - OBRIGATÓRIO: NÃO
            </pre>
          </v-row>
        </v-card-text> -->
        <v-tabs v-model="tab">
          <v-tab>Novos cadastros</v-tab>
          <v-tab>Editados</v-tab>
          <v-tab>Com erro</v-tab>
        </v-tabs>
        <v-tabs-items class="mt-4" v-model="tab">
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd2.filter((val) => val.tipo === 'insert')"
            >
            <template v-slot:item.msg="{ item }">
              {{ item.msg.match(/Item (.*?) inserido/)[1] }}
            </template>
          </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd2.filter((val) => val.tipo === 'update')"
            >
            <template v-slot:item.msg="{ item }">
              {{ item.msg.match(/Item (.*?) alterado/)[1] }}
            </template>
          </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd.filter((val) => !val.ok)"
            >
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </transition>
    
  </article>
</template>

<script>
import { fecharPagina } from '../../../functions/fechar_pagina';
import { baseApiUrl, showSuccess, showError } from '@/global';
import axios from "axios";

export default {
  name: "ImportarVolumesExtras",

  data: () => ({
    uploadFiles: [],
    carregando: false,
    dataProd: [],
    dataProd2: [],
    tab: null,
    headers: [
      { text: 'Código do Produto', value: 'msg', sortable: false }
    ]
  }),

  methods: {
    fecharPagina,

    async importar(){
      this.carregando = true;
      let url = `${baseApiUrl}/produtos-importar?tipo=2`;
      this.dataProd = []
      this.dataprod2 = []
      const data = new FormData();
      data.append('arquivo', this.uploadFiles);

      let config = { header : { 'Content-Type' : 'multipart/form-data' } };

      await axios
        .post(url, data, config)
        .then((res) => {
          if(!res.data.retorno){
            showError("Não foi possível importar o arquivo");
          }else{
            showSuccess("Arquivo importado com sucesso!");
            this.dataProd = [...res.data.dados]
            this.dataProd.forEach((val) => {
              if(Array.isArray(val.msg)) {
                val.msg.forEach((x) => {
                  this.dataProd2.push(x[0])
                })
              }
            })
          }
        }).catch(() => {
          showError("Não foi possível importar o arquivo");
        }).finally(() => {
          this.carregando = false;
        })
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>